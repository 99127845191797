import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/index.vue'),
        children: [{
                path: '/',
                name: 'home',
                component: () =>
                    import ('../views/Home.vue'),
                meta: {
                    index: 0
                }
            },
            {
                path: '/about/:id',
                name: 'about',
                component: () =>
                    import ('../views/About.vue'),
                meta: {
                    index: 1
                }
            },
            // 列表页
            {
                path: '/list/:id',
                name: 'list',
                component: () =>
                    import ('../views/list.vue'),
                meta: {
                    index: 2
                }
            },
            // 详情页
            {
                path: '/detail/:parentId/:id',
                name: 'detail',
                component: () =>
                    import ('../views/detail.vue'),
                meta: {
                    index: 2
                }
            },
            // 栏目详情
            {
                path: '/listPage/:id',
                name: 'listPage',
                component: () =>
                    import ('../views/listPage.vue'),
                meta: {
                    index: 1
                }
            },
            // 图片列表
            {
                path: '/listPic/:id',
                name: 'listPic',
                component: () =>
                    import ('../views/listPic.vue'),
                meta: {
                    index: 1
                }
            },
            // 举报
            {
                path: '/report/:id',
                name: 'listPic',
                component: () =>
                    import ('../views/report.vue'),
                meta: {
                    index: 1
                }
            },
            // 搜索
            {
                path: '/search/:title',
                name: 'listPic',
                component: () =>
                    import ('../views/search.vue'),
                meta: {
                    index: 1
                }
            },
            // 站群
            {
                path: '/organization',
                name: 'listPic',
                component: () =>
                    import ('../views/organization.vue'),
                meta: {
                    index: 1
                }
            }
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router